import { Button } from '@iheartradio/web.accomplice/button';
import { Image } from '@iheartradio/web.accomplice/image';
import { getResponsiveImgAttributes } from '@iheartradio/web.assets';
import { $path } from 'remix-routes';

import { useRegGateEvent } from '~app/analytics/use-reg-gate';
import type { FavoritesStation, MadeForYouRecs } from '~app/api/types';
import { StaticImage } from '~app/components/made-for-you-cta/image-helpers';
import {
  MadeForYouCTA,
  madeForYouImageUrls,
} from '~app/components/made-for-you-cta/made-for-you-cta';
import { useIsMobile } from '~app/contexts/is-mobile';
import { useUser } from '~app/contexts/user';
import { useGetImageDominantColor } from '~app/hooks/use-get-image-dominant-color';
import { MadeForYouCarousel } from '~app/routes/_app/_index/components/made-for-you-carousel';
import { REG_GATE_TRIGGER_TYPES, Routes } from '~app/utilities/constants';

export function MadeForYou(props: {
  myFavoritesRadio: FavoritesStation | null;
  playlists: MadeForYouRecs;
  pageName: string;
  sectionPosition: number;
}) {
  const { myFavoritesRadio, playlists, pageName, sectionPosition } = props;

  const carouselBackgroundColor = useGetImageDominantColor(
    playlists?.[0]?.urls?.image,
  );

  const user = useUser();
  const isMobile = useIsMobile();
  const { onAnalyticsRegGateOpen } = useRegGateEvent();

  if (user?.isAnonymous) {
    return (
      <MadeForYouCTA
        buttons={
          <>
            <Button
              color="red"
              href={$path(Routes.Login, { action: 'auth' })}
              kind="primary"
              onPress={() =>
                onAnalyticsRegGateOpen(REG_GATE_TRIGGER_TYPES.MADE_FOR_YOU)
              }
              size="small"
            >
              Log in
            </Button>
            <Button
              color={{ dark: 'white', light: 'gray' }}
              href={$path(Routes.SignUp)}
              kind="secondary"
              onPress={() =>
                onAnalyticsRegGateOpen(REG_GATE_TRIGGER_TYPES.MADE_FOR_YOU)
              }
              size="small"
            >
              Sign up
            </Button>
          </>
        }
        description="Save more content and unlock your personalized playlists"
        images={
          <StaticImage>
            <Image
              alt="Your Favorites Radio"
              aspectRatio="1 / 1"
              css={{
                borderRadius: '$6',
              }}
              {...getResponsiveImgAttributes(
                madeForYouImageUrls.favoritesRadio,
                {
                  width: isMobile ? 60 : 125,
                  ratio: [1, 1],
                  quality: isMobile ? 40 : 75,
                },
              )}
            />
            <Image
              alt="Your Weekly Mixtape"
              aspectRatio="1 / 1"
              css={{
                borderRadius: '$6',
                display: {
                  mobile: 'none',
                  xlarge: 'initial',
                },
              }}
              {...getResponsiveImgAttributes(
                madeForYouImageUrls.weeklyMixtape,
                {
                  width: isMobile ? 60 : 125,
                  ratio: [1, 1],
                  quality: isMobile ? 40 : 75,
                },
              )}
            />
          </StaticImage>
        }
        title="Made For You"
      />
    );
  }

  return (
    <MadeForYouCarousel
      color={carouselBackgroundColor}
      myFavoritesRadio={myFavoritesRadio}
      pageName={pageName}
      playlistRecs={playlists}
      sectionPosition={sectionPosition}
    />
  );
}
