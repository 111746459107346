import { Text } from '@iheartradio/web.accomplice/text';
import { isNumber } from 'remeda';

import type { RadioDialFilterState } from '../state/radio-dial-filter-state';

export function ListBoxEmptyState({
  inputValue,
  filterState,
}: {
  inputValue: string;
  filterState: RadioDialFilterState;
}) {
  const maybeZip = isNumber(Number(inputValue));

  const text =
    maybeZip ?
      filterState.invalidZip ?
        'Please enter a valid ZIP code'
      : 'Continue Entering ZIP code'
    : 'No results found. Please try again';

  return (
    <Text
      as="p"
      css={{ textAlign: 'center', padding: '$8' }}
      data-test="radio-dial-listbox-empty-text"
      kind={{ mobile: 'subtitle-4', medium: 'subtitle-4' }}
    >
      {text}
    </Text>
  );
}
