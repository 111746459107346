import { Button } from '@iheartradio/web.accomplice/button';
import { Flex } from '@iheartradio/web.accomplice/flex';
import { Search } from '@iheartradio/web.accomplice/icons';
import { Text } from '@iheartradio/web.accomplice/text';
import { addToast } from '@iheartradio/web.accomplice/toast';
import { isBlank } from '@iheartradio/web.utilities';
import {
  type ClientLoaderFunction,
  useLoaderData,
  useRevalidator,
  useSearchParams,
} from '@remix-run/react';
import { useNavigate } from '@remix-run/react';
import { useEffect, useTransition } from 'react';
import { $path } from 'remix-routes';
import type { Simplify } from 'type-fest';

import { InlineAd } from '~app/ads/display/inline-ad';
import { usePageView } from '~app/analytics/use-page-view';
import { Deferred } from '~app/components/deferred';
import { RadioDial } from '~app/components/radio-dial';
import { RecentlyPlayedCarousel } from '~app/components/recently-played/recently-played-carousel';
import { useConfig } from '~app/contexts/config';
import { playback } from '~app/playback/playback';
import type { HomeClientLoaderData } from '~app/routes/api.v1.home';
import { Routes } from '~app/utilities/constants';
import {
  fetchClientData,
  fetchWithTimeout,
} from '~app/utilities/fetch-client-data';

import type { HomeServerLoaderData } from './.server/loader';
import { HomeHero } from './components/_home-hero/home-hero';
// Don't lazy load the components, since they are now all rendered no matter what
// This speeds up the rendering of the home route
import { DownloadTheAppRow } from './components/download-the-app';
import { FeaturedPlaylistsCarousel } from './components/featured-playlists-carousel';
import { FeaturedPodcastsCarousel } from './components/featured-podcasts-carousel';
import { LocalLiveStationsCarousel } from './components/local-live-stations-carousel';
import { MadeForYou } from './components/made-for-you';
import { PlaylistsByGenreCarousel } from './components/playlists-by-genre-carousel';
import { PopularNewsCarousel } from './components/popular-news-carousel';
import { PresetsCarousel } from './components/presets-carousel';
import { RecommendedArtistsCarousel } from './components/recommended-artists-carousel';
import { RecommendedPlaylistsCarousel } from './components/recommended-playlists-carousel';
import { RecommendedPodcastsCarousel } from './components/recommended-podcasts-carousel';
import { TopArtistsCarousel } from './components/top-artists-carousel';
import { TopPlaylistsCarousel } from './components/top-playlists-carousel';
import { TopPodcastsCarousel } from './components/top-podcasts-carousel';
import { TrendingSection } from './components/trending-section';
import { LOADER_MESSAGES } from './constants';

export { loader } from './.server/loader';
export { meta } from './meta';
export { headers } from '~app/defaults.server';

export type HomeLoaderData = Simplify<
  HomeServerLoaderData &
    HomeClientLoaderData & { clientLoaderResolved: boolean }
>;

export const clientLoader: ClientLoaderFunction = async ({
  request,
  serverLoader,
}) => {
  const serverData = await serverLoader<HomeServerLoaderData>();

  // Build the url for client loader data request from the url passed to this loader
  const { origin, searchParams } = new URL(request.url);
  const clientDataRequestUrl = new URL(
    $path('/api/v1/home', {
      ...Object.fromEntries(searchParams.entries()),
      rankers: serverData.isRankerEnabled,
    }),
    origin,
  );

  // Fetch all user-specific data from the resource route
  const clientData = await fetchClientData<HomeClientLoaderData>(
    fetchWithTimeout(clientDataRequestUrl),
    {
      madeForYouData: { madeForYouRecs: [], myFavoritesRadio: null },
      playlistRecs: [],
      popularNewsItems: [],
      recentlyPlayedStations: [],
      recommendedArtists: [],
      recommendedPodcasts: [],
    },
  );

  return {
    ...serverData,
    ...clientData,
    clientLoaderResolved: true,
  };
};
clientLoader.hydrate = true;

export default function Home() {
  const {
    currentPodcastCategory,
    currentGenre,
    currentPlaylistGenre,
    featuredPlaylistsData,
    featuredPodcastsData,
    genres,
    internalCampaign,
    isAnonymousUser,
    isRankerEnabled,
    liveStations,
    madeForYouData,
    pageName,
    playlistGenres,
    playlistMoods,
    playlistRecs,
    playlistsByGenre,
    podcastCategories,
    popularNewsItems,
    recentlyPlayedStations,
    recommendedArtists,
    recommendedPodcasts,
    selectedMood,
    topArtists,
    topPlaylists,
    topPodcasts,
    trendingData,
    clientLoaderResolved,
  } = useLoaderData<HomeLoaderData>();

  const [, startTransition] = useTransition();

  // ---------------
  // While on the homepage, we should update the "Recently Played" section whenever a user
  // plays content from this view

  // Get a reference to the player and revalidator
  const player = playback.usePlayer();
  const revalidator = useRevalidator();
  const navigate = useNavigate();

  // In `useEffect`, subscribe to `player.play` and revalidate on any play action
  // `return player.subscribe` will call the unsubscribe function when this component
  // unmounts
  useEffect(() => {
    return player.subscribe({
      play() {
        if (!player.getState().get('isScanning')) {
          startTransition(() => {
            revalidator.revalidate();
          });
        }
      },
    });
  }, [player, revalidator]);
  // ---------------

  const [searchParams, setSearchParams] = useSearchParams();
  const config = useConfig();

  // TODO: Is this the *best* place for this???? [DEM 2024/11/19]
  // If root loader detected an invalid session, the user will be redirected to home with a
  // query param of `?message=badsession`. This effect looks for that message key and displays
  // a toast notification notifying the user they need to log in again
  useEffect(() => {
    if (searchParams.get('message') === LOADER_MESSAGES.BAD_SESSION) {
      addToast({
        kind: 'error',
        timeout: 10_000,
        title: 'Session expired',
        text: 'Please log in again',
        actions: [
          {
            kind: 'primary',
            color: 'white',
            content: 'Log In',
            size: 'small',
            onPress: () => {
              navigate(`/login/auth?redirectUrl=${window.location.href}`);
            },
          },
        ],
      });

      setSearchParams(previous => {
        previous.delete('message');
        return previous;
      });
    }
  }, [searchParams, setSearchParams, config.urls.account]);

  usePageView(pageName);

  let sectionPosition = 0;

  return (
    <Flex direction="column" gap="$32" width="100%">
      <HomeHero campaignData={internalCampaign} />
      <PresetsCarousel />
      <RadioDial />
      {isRankerEnabled ?
        <>
          <Deferred resolve={trendingData}>
            {function TrendingData(trendingData) {
              return isBlank(trendingData) ? null : (
                  <TrendingSection data={trendingData} />
                );
            }}
          </Deferred>
          <Deferred resolve={topPodcasts}>
            {topPodcasts =>
              isBlank(podcastCategories) ? null : (
                <>
                  <TopPodcastsCarousel
                    pageName={pageName}
                    podcastCategories={podcastCategories}
                    podcasts={topPodcasts}
                    sectionPosition={sectionPosition++}
                    selectedCategoryID={currentPodcastCategory}
                  />
                  <InlineAd />
                </>
              )
            }
          </Deferred>
          <Deferred resolve={topPlaylists}>
            {topPlaylists =>
              isBlank(playlistGenres) || isBlank(playlistMoods) ?
                null
              : <TopPlaylistsCarousel
                  currentMood={selectedMood}
                  currentPlaylistGenre={currentPlaylistGenre}
                  pageName={pageName}
                  playlistGenres={playlistGenres}
                  playlistMoods={playlistMoods}
                  playlists={topPlaylists}
                  sectionPosition={sectionPosition++}
                />
            }
          </Deferred>
          <Deferred resolve={topArtists}>
            {topArtists =>
              isBlank(genres) ? null : (
                <TopArtistsCarousel
                  artistGenres={genres}
                  artists={topArtists}
                  currentGenre={currentGenre}
                  pageName={pageName}
                  sectionPosition={sectionPosition++}
                />
              )
            }
          </Deferred>
          <Deferred resolve={recentlyPlayedStations}>
            {recentlyPlayed => (
              <RecentlyPlayedCarousel
                clientLoaderResolved={clientLoaderResolved}
                pageName={pageName}
                sectionPosition={sectionPosition++}
                stations={recentlyPlayed}
              />
            )}
          </Deferred>
        </>
      : <>
          <Deferred resolve={liveStations}>
            {liveStations => (
              <LocalLiveStationsCarousel
                liveStations={liveStations}
                pageName={pageName}
                sectionPosition={sectionPosition++}
              />
            )}
          </Deferred>
          <Deferred resolve={recommendedPodcasts}>
            {recommendedPodcasts => (
              <>
                <RecommendedPodcastsCarousel
                  pageName={pageName}
                  podcasts={recommendedPodcasts}
                  sectionPosition={sectionPosition++}
                  title={
                    isAnonymousUser ?
                      "Today's Top Podcasts"
                    : 'Podcasts You Might Like'
                  }
                />
                <InlineAd />
              </>
            )}
          </Deferred>
          <Deferred resolve={recommendedArtists}>
            {recommendedArtists => (
              <RecommendedArtistsCarousel
                artists={recommendedArtists}
                pageName={pageName}
                sectionPosition={sectionPosition++}
                sectionTitle={
                  isAnonymousUser ? 'Popular Artists' : 'Artists You Might Like'
                }
              />
            )}
          </Deferred>
          <Deferred resolve={featuredPodcastsData}>
            {({ featuredPodcasts }) => (
              <FeaturedPodcastsCarousel
                pageName={pageName}
                podcasts={featuredPodcasts}
                sectionPosition={sectionPosition++}
              />
            )}
          </Deferred>
          <Deferred resolve={madeForYouData}>
            {({ madeForYouRecs, myFavoritesRadio }) => (
              <MadeForYou
                myFavoritesRadio={myFavoritesRadio}
                pageName={pageName}
                playlists={madeForYouRecs}
                sectionPosition={sectionPosition++}
              />
            )}
          </Deferred>
          <Deferred resolve={playlistRecs}>
            {playlistRecs => (
              <RecommendedPlaylistsCarousel
                pageName={pageName}
                playlists={playlistRecs}
                sectionPosition={sectionPosition++}
              />
            )}
          </Deferred>
          <Deferred resolve={featuredPlaylistsData}>
            {({ featuredPlaylists }) =>
              isBlank(featuredPlaylists) || featuredPlaylists.length === 0 ?
                null
              : <FeaturedPlaylistsCarousel
                  pageName={pageName}
                  playlists={featuredPlaylists}
                  sectionPosition={sectionPosition++}
                />
            }
          </Deferred>
          <Deferred resolve={playlistsByGenre}>
            {({ playlistGenres, playlists, selectedPlaylistGenre }) =>
              isBlank(playlists) ? null : (
                <PlaylistsByGenreCarousel
                  key={selectedPlaylistGenre}
                  pageName={pageName}
                  playlistGenres={playlistGenres}
                  playlistsByGenre={playlists}
                  sectionPosition={sectionPosition++}
                  selectedPlaylistGenre={selectedPlaylistGenre}
                />
              )
            }
          </Deferred>
          <Deferred resolve={popularNewsItems}>
            {popularNewsItems =>
              isBlank(popularNewsItems) ? null : (
                <PopularNewsCarousel
                  pageName={pageName}
                  popularNewsItems={popularNewsItems}
                  sectionPosition={sectionPosition++}
                />
              )
            }
          </Deferred>
          <Deferred resolve={recentlyPlayedStations}>
            {recentlyPlayed => (
              <RecentlyPlayedCarousel
                clientLoaderResolved={clientLoaderResolved}
                pageName={pageName}
                sectionPosition={sectionPosition++}
                stations={recentlyPlayed}
              />
            )}
          </Deferred>
        </>
      }

      <Flex direction="row" justifyContent="center" padding="0 $16">
        <Flex alignItems="center" gap="$12" justifyContent="space-around">
          <Text kind="subtitle-2">Looking for something specific?</Text>
          <Button
            color={{ dark: 'white', light: 'gray' }}
            kind="secondary"
            onPress={() =>
              navigate($path(Routes.Search), {
                state: { prevPage: '/home' },
              })
            }
            size={{ mobile: 'small', medium: 'large' }}
          >
            <Search size={18} />
            Search
          </Button>
        </Flex>
      </Flex>
      <DownloadTheAppRow />
    </Flex>
  );
}
