import { ThemeEnum, useTheme } from '@iheartradio/web.accomplice';
import { Box } from '@iheartradio/web.accomplice/box';
import { Flex } from '@iheartradio/web.accomplice/flex';
import { Text } from '@iheartradio/web.accomplice/text';
import { MediaServerURL } from '@iheartradio/web.assets';

export const madeForYouImageUrls = {
  favoritesRadio:
    'https://i.iheart.com/v3/url/aHR0cHMlM0ElMkYlMkZ3ZWItc3RhdGljLnBhZ2VzLmloZWFydC5jb20lMkZpbWclMkZkZWZhdWx0X2Zhdm9yaXRlLnBuZw==',
  weeklyMixtape:
    'https://i.iheart.com/v3/re/new_assets/622922797e7648c322b6ebe4',
};

interface MadeForYouCTAProps {
  title: string;
  description: string;
  buttons?: React.ReactNode;
  images?: React.ReactNode;
}

export const MadeForYouCTA = ({
  title,
  description,
  buttons,
  images,
}: MadeForYouCTAProps) => {
  const theme = useTheme();
  return (
    <Flex
      direction="row"
      height="19.2rem"
      justifyContent="center"
      overflow="hidden"
      padding={{ mobile: '$16', large: '$16 $32' }}
      position="relative"
      zIndex="$0"
    >
      <Box
        backgroundImage={`url('${MediaServerURL.fromURL(
          madeForYouImageUrls.favoritesRadio,
        )
          .scale(125)
          .quality(1)
          .toString()}')`}
        backgroundPosition="center"
        backgroundSize="cover"
        filter="blur(4rem)"
        height="100%"
        position="absolute"
        top="$0"
        width="100%"
      />
      <Box
        background={
          theme === ThemeEnum.light ?
            'linear-gradient(to right, rgba(246, 248, 249, 100%), rgba(255, 255, 255, 50%), rgba(246, 248, 249, 100%))'
          : 'linear-gradient(to right, rgba(0, 0, 0, 90%), rgba(0, 0, 0, 60%), rgba(0, 0, 0, 90%))'
        }
        backgroundPosition="center"
        backgroundSize="cover"
        height="100%"
        position="absolute"
        top="0"
        width="100%"
        zIndex="$1"
      />
      <Flex
        alignItems="center"
        direction="row"
        gap={{ mobile: '$24', large: '$64' }}
        justifyContent="center"
        zIndex="$2"
      >
        <Flex direction="column" gap="$16" justifyContent="center">
          <Flex direction="column" gap="$4" justifyContent="center">
            <Text as="h3" kind={{ mobile: 'h4', large: 'h3' }} lines={2}>
              {title}
            </Text>
            <Text kind="body-3" lines={3}>
              {description}
            </Text>
          </Flex>
          {buttons ?
            <Flex direction="row" gap="$12">
              {buttons}
            </Flex>
          : null}
        </Flex>
        {images}
      </Flex>
    </Flex>
  );
};
