import { defaultMXMarket, defaultUSMarket } from '~app/utilities/constants';

export type RadioDialFilterState = {
  selectedCountryCode: string;
  activeMarketId: number;
  geoMarketId: number;
  selectedGenreId: number;
  queryByZip?: string;
  invalidZip: boolean;
};

type UpdateActiveMarketAction = {
  type: 'updateActiveMarket';
  payload: { marketId: number };
};
type UpdateSelectedGenreIdAction = {
  type: 'updateSelectedGenreId';
  payload: { genreId: number };
};
type UpdateSelectedCountryCodeAction = {
  type: 'updateSelectedCountryCode';
  payload: { countryCode: string };
};
type QueryMarketsByZipAction = {
  type: 'queryMarketsByZip';
  payload: { zipCode: string | undefined };
};
type InvalidZipAction = { type: 'invalidZip'; payload: { isInvalid: boolean } };

type Actions = Pick<
  | UpdateActiveMarketAction
  | UpdateSelectedCountryCodeAction
  | UpdateSelectedGenreIdAction
  | QueryMarketsByZipAction
  | InvalidZipAction,
  'type'
>['type'];

type ActionPayload<T extends Actions> = Extract<
  | UpdateActiveMarketAction
  | UpdateSelectedCountryCodeAction
  | UpdateSelectedGenreIdAction
  | QueryMarketsByZipAction
  | InvalidZipAction,
  { type: T }
>['payload'];
type BatchPayload = { [K in Actions]?: ActionPayload<K> };

export type RadioDialFilterAction =
  | UpdateActiveMarketAction
  | UpdateSelectedGenreIdAction
  | UpdateSelectedCountryCodeAction
  | QueryMarketsByZipAction
  | InvalidZipAction
  | {
      type: 'batchUpdate';
      payload: BatchPayload;
    };

export function radioDialFilterStateReducer(
  state: RadioDialFilterState,
  action: RadioDialFilterAction,
): RadioDialFilterState {
  const { type, payload } = action;
  switch (type) {
    case 'updateActiveMarket': {
      if (payload.marketId > 0) {
        window.sessionStorage.setItem('marketId', String(payload.marketId));
      }
      return {
        ...state,
        activeMarketId: payload.marketId,
      };
    }
    case 'updateSelectedGenreId': {
      window.sessionStorage.setItem('radioDialGenre', String(payload.genreId));
      return {
        ...state,
        selectedGenreId: payload.genreId,
      };
    }
    case 'updateSelectedCountryCode': {
      window.sessionStorage.setItem('radioDialGenre', '0');
      window.sessionStorage.setItem('radioDialCountry', payload.countryCode);
      return {
        ...state,
        selectedCountryCode: payload.countryCode,
        selectedGenreId: 0,
      };
    }
    case 'queryMarketsByZip': {
      return {
        ...state,
        queryByZip: payload.zipCode,
      };
    }
    case 'invalidZip': {
      return {
        ...state,
        invalidZip: payload.isInvalid,
      };
    }
    case 'batchUpdate': {
      const newState = { ...state };

      for (const [key, value] of Object.entries(payload) as [
        Actions,
        ActionPayload<Actions>,
      ][]) {
        switch (key) {
          case 'updateActiveMarket': {
            if ((value as ActionPayload<'updateActiveMarket'>).marketId > 0) {
              window.sessionStorage.setItem(
                'marketId',
                String((value as ActionPayload<'updateActiveMarket'>).marketId),
              );
            }
            newState['activeMarketId'] = (
              value as ActionPayload<'updateActiveMarket'>
            ).marketId;
            break;
          }
          case 'invalidZip': {
            newState['invalidZip'] = (
              value as ActionPayload<'invalidZip'>
            ).isInvalid;
            break;
          }
          case 'queryMarketsByZip': {
            newState['queryByZip'] = (
              value as ActionPayload<'queryMarketsByZip'>
            ).zipCode;
            break;
          }
          case 'updateSelectedCountryCode': {
            window.sessionStorage.setItem('radioDialGenre', '0');
            window.sessionStorage.setItem(
              'radioDialCountry',
              (value as ActionPayload<'updateSelectedCountryCode'>).countryCode,
            );
            newState['selectedCountryCode'] = (
              value as ActionPayload<'updateSelectedCountryCode'>
            ).countryCode;
            break;
          }
          case 'updateSelectedGenreId': {
            window.sessionStorage.setItem(
              'radioDialGenre',
              String((value as ActionPayload<'updateSelectedGenreId'>).genreId),
            );
            newState['selectedGenreId'] = (
              value as ActionPayload<'updateSelectedGenreId'>
            ).genreId;
            break;
          }
        }
      }

      return newState;
    }
  }
}

export function createInitialFilterState({
  countryCode,
  isBrowser,
}: {
  countryCode: string;
  isBrowser: boolean;
}): RadioDialFilterState {
  const marketId = isBrowser ? window.sessionStorage.getItem('marketId') : null;
  const geoMarketId =
    isBrowser ? window.sessionStorage.getItem('geoMarketId') : null;
  const genreId =
    isBrowser ? window.sessionStorage.getItem('radioDialGenre') : null;
  const radioDialCountry =
    isBrowser ?
      (window.sessionStorage.getItem('radioDialCountry') ?? countryCode)
    : countryCode;

  const defaultMarket =
    radioDialCountry === 'MX' ? defaultMXMarket : defaultUSMarket;

  return {
    activeMarketId: marketId ? Number(marketId) : defaultMarket.marketId,
    geoMarketId:
      geoMarketId ? Number(geoMarketId)
      : marketId ? Number(marketId)
      : defaultMarket.marketId,
    selectedGenreId: genreId ? Number(genreId) : 0,
    selectedCountryCode: radioDialCountry ?? countryCode,
    invalidZip: false,
  };
}
