import { lightDark } from '@iheartradio/web.accomplice';
import type { ButtonProps } from '@iheartradio/web.accomplice/button';
import { Button } from '@iheartradio/web.accomplice/button';
import { Scan } from '@iheartradio/web.accomplice/icons';
import { Text } from '@iheartradio/web.accomplice/text';

import { playback } from '~app/playback/playback';

export function ScanButton(props: ButtonProps) {
  const { isScanning } = playback.useState();

  return (
    <Button
      color="transparent"
      css={{
        backgroundColor: {
          default:
            isScanning ? '$red550' : lightDark('$brandWhite', '$gray600'),
          hover: isScanning ? '$red650' : lightDark('$gray200', '$gray500'),
        },
        maxWidth: '80rem',
        width: '100%',
      }}
      kind="primary"
      {...props}
    >
      <Scan
        color={
          isScanning ? '$brandWhite'
          : props.isDisabled ?
            '$gray400'
          : '$red550'
        }
      />
      <Text
        as="span"
        color={
          isScanning ? '$brandWhite'
          : props.isDisabled ?
            'inherit'
          : '$red550'
        }
        kind="button-2"
      >
        {isScanning ? 'Stop Scan' : 'Scan Stations'}
      </Text>
    </Button>
  );
}
