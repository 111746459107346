import { Button, RACButton } from '@iheartradio/web.accomplice/button';
import type { ComboboxItemType } from '@iheartradio/web.accomplice/combobox';
import { Combobox, ComboboxItem } from '@iheartradio/web.accomplice/combobox';
import {
  Dialog,
  DialogTitle,
  DialogTrigger,
} from '@iheartradio/web.accomplice/dialog';
import { Flex } from '@iheartradio/web.accomplice/flex';
import { Stack } from '@iheartradio/web.accomplice/stack';
import { Text } from '@iheartradio/web.accomplice/text';
import { isUndefined } from '@iheartradio/web.utilities';
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from '@tanstack/react-query';
import ms from 'ms';
import type { Dispatch, Key } from 'react';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import { isNonNullish } from 'remeda';

import { useConfig } from '~app/contexts/config';

import { ScanContext } from '../radio-dial';
import type { RadioDialAction } from '../state/radio-dial-data';
import { ListBoxEmptyState } from './components/list-box-empty-state';
import { RadioDialComboboxSection } from './components/radio-dial-combobox-section';
import type { Market } from './state/queries';
import { queryMarketByZipCode } from './state/queries';
import { radioDialFilterDataReducer } from './state/radio-dial-filter-data';
import {
  createInitialFilterState,
  radioDialFilterStateReducer,
} from './state/radio-dial-filter-state';
import { useFilterData } from './state/use-filter-data';

export interface RadioDialComboboxItem
  extends Exclude<ComboboxItemType, 'children'> {
  markSelected?: boolean;
  itemId?: string | number;
  children?: RadioDialComboboxItem[];
}

/**
 * Radio Dial filters component.
 *
 * Uses `@tanstack/react-query` for data fetching from AMP.
 *
 * @param param0 Render function to display stations resulting from the filter selections
 * @returns JSX.Element
 */
export function RadioDialFilters({
  radioDialDispatch,
}: {
  radioDialDispatch: Dispatch<RadioDialAction>;
}) {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: ms('4h'),
          },
        },
      }),
  );

  return (
    <QueryClientProvider client={queryClient}>
      <_RadioDialFilters radioDialDispatch={radioDialDispatch} />
    </QueryClientProvider>
  );
}

function _RadioDialFilters({
  radioDialDispatch,
}: {
  radioDialDispatch: Dispatch<RadioDialAction>;
}) {
  const { environment } = useConfig();
  const { countryCode } = environment;
  const isBrowser = !isUndefined(globalThis.window);

  const { stopScan } = useContext(ScanContext);

  const filterStateArg = useMemo(
    () => ({ countryCode, isBrowser }),
    [countryCode, isBrowser],
  );

  const [filterState, updateFilterState] = useReducer(
    radioDialFilterStateReducer,
    filterStateArg,
    createInitialFilterState,
  );

  const [filterData, updateFilterData] = useReducer(
    radioDialFilterDataReducer,
    {
      countries: [],
      markets: [],
      genres: [],
      stations: [],
      geoMarket: {} as Market,
    },
  );

  const [isChangingLocation, setIsChangingLocation] = useState<boolean>(false);

  const { isFetching } = useFilterData(
    filterState,
    updateFilterData,
    updateFilterState,
  );
  const marketByZipCodeQuery = useQuery({
    queryKey: ['marketByZipCode', filterState.queryByZip],
    queryFn: () => queryMarketByZipCode(filterState.queryByZip),
  });

  const locationItems = useMemo<RadioDialComboboxItem[]>(() => {
    const items: RadioDialComboboxItem[] = [];

    const currentCountry = filterData.countries.find(
      country => country.abbreviation === filterState.selectedCountryCode,
    );
    const geoMarket = filterData.geoMarket;

    if (currentCountry) {
      items.push({
        id: 'location',
        children: [
          {
            id: 'use-current-location',
            name:
              geoMarket ?
                `${geoMarket.city}, ${geoMarket.stateAbbreviation}`
              : '',
            itemId: geoMarket?.marketId,
            markSelected: geoMarket?.marketId == filterState.activeMarketId,
          },
          {
            id: `country-${currentCountry.abbreviation}`,
            name: currentCountry.name,
          },
        ],
      });
    }

    items.push({
      id: 'markets',
      children: filterData.markets.map(market => ({
        id: `market-${market.marketId}`,
        name: market.displayName,
        itemId: market.marketId,
      })),
    });

    return items;
  }, [
    filterData.countries,
    filterData.markets,
    filterState.selectedCountryCode,
    filterState.activeMarketId,
    filterData.geoMarket,
  ]);

  const genreItems = useMemo<RadioDialComboboxItem[]>(() => {
    const items: RadioDialComboboxItem[] = [];

    items.push(
      ...filterData.genres.map(genre => ({
        id: `genre-${genre.id}`,
        name: genre.genreName,
      })),
    );

    return items;
  }, [filterData.genres]);

  const onChange = useCallback(
    (data: Key | null) => {
      if (data?.toString().startsWith('country-')) {
        setIsChangingLocation(true);
      } else if (data?.toString().startsWith('market-')) {
        const marketId = Number(data.toString().replace('market-', ''));
        updateFilterState({
          type: 'updateActiveMarket',
          payload: {
            marketId,
          },
        });
      } else if (data?.toString().startsWith('genre-')) {
        const genreId = Number(data.toString().replace('genre-', ''));
        updateFilterState({
          type: 'updateSelectedGenreId',
          payload: {
            genreId,
          },
        });
      } else if (data?.toString() === 'use-current-location') {
        updateFilterState({
          type: 'batchUpdate',
          payload: {
            updateActiveMarket: { marketId: filterState.geoMarketId },
            ...((
              filterState.selectedCountryCode !==
              filterData.geoMarket.countryAbbreviation
            ) ?
              {
                updateSelectedCountryCode: {
                  countryCode: filterData.geoMarket.countryAbbreviation,
                },
              }
            : {}),
          },
        });
      }
    },
    [
      filterState.selectedCountryCode,
      filterState.geoMarketId,
      filterData.geoMarket.countryAbbreviation,
    ],
  );

  const onInputChange = useCallback((value: string) => {
    if (/\d{5}/.test(value)) {
      updateFilterState({
        type: 'queryMarketsByZip',
        payload: {
          zipCode: value,
        },
      });
    }
  }, []);

  useEffect(() => {
    if (filterState.queryByZip && marketByZipCodeQuery.status === 'success') {
      const matchedMarket = filterData.markets.find(
        market => market.marketId === marketByZipCodeQuery.data?.marketId,
      );

      if (matchedMarket) {
        updateFilterState({
          type: 'batchUpdate',
          payload: {
            updateActiveMarket: { marketId: matchedMarket.marketId },
            queryMarketsByZip: { zipCode: undefined },
            invalidZip: { isInvalid: false },
          },
        });
      } else {
        updateFilterState({
          type: 'batchUpdate',
          payload: {
            invalidZip: { isInvalid: true },
            queryMarketsByZip: { zipCode: undefined },
          },
        });
      }
    }
  }, [
    filterData.markets,
    filterState.queryByZip,
    marketByZipCodeQuery.data?.marketId,
    marketByZipCodeQuery.status,
  ]);

  useEffect(() => {
    radioDialDispatch({
      type: 'updateStations',
      payload: {
        stations: filterData.stations,
      },
    });
    stopScan();
  }, [filterData.stations, radioDialDispatch, stopScan]);

  useEffect(() => {
    radioDialDispatch({
      type: 'updateFetchStatus',
      payload: {
        isFetching,
      },
    });
  }, [isFetching, radioDialDispatch]);

  useEffect(() => {
    radioDialDispatch({
      type: 'updateLocation',
      payload: {
        location:
          filterData.markets.find(
            market => market.marketId === filterState.activeMarketId,
          )?.displayName ?? '',
      },
    });
  }, [filterData.markets, filterState.activeMarketId, radioDialDispatch]);

  useEffect(() => {
    radioDialDispatch({
      type: 'updateGenre',
      payload: {
        genre:
          filterData.genres.find(
            genre => genre.id === filterState.selectedGenreId,
          )?.genreName ?? 'All Genres',
      },
    });
  }, [filterData.genres, filterState.selectedGenreId, radioDialDispatch]);

  return (
    <>
      <Flex
        alignItems="center"
        data-test="live-radio-dial-filters"
        direction="row"
        gap={{ xsmall: '$8', large: '$16' }}
        justifyContent="flex-start"
        width="100%"
      >
        <Text
          as="p"
          css={{ whiteSpace: 'nowrap' }}
          kind={{ xsmall: 'h5', large: 'h3' }}
        >
          Live Radio Dial
        </Text>

        <Combobox
          allowsCustomValue
          data-test="live-radio-dial-location"
          defaultItems={locationItems}
          onInputChange={onInputChange}
          onSelectionChange={onChange}
          placeholder="Loading Cities..."
          popoverProps={{ maxHeight: 400 }}
          renderEmptyState={({ state }) => {
            return (
              <ListBoxEmptyState
                filterState={filterState}
                // @ts-expect-error inputValue *does* exist, I just can't find it in the types
                inputValue={state.inputValue}
              />
            );
          }}
          selectedKey={`market-${filterState.activeMarketId}`}
        >
          {item =>
            isNonNullish(item.children) ?
              <RadioDialComboboxSection section={item} />
            : <ComboboxItem
                data-test={item.id}
                key={item.id}
                textValue={item.name}
              >
                <Text kind="caption-2">{item.name}</Text>
              </ComboboxItem>
          }
        </Combobox>
        <Combobox
          data-test="live-radio-dial-genre"
          defaultItems={genreItems}
          onSelectionChange={onChange}
          placeholder="Loading Genres..."
          popoverProps={{ maxHeight: 400 }}
          selectedKey={`genre-${filterState.selectedGenreId}`}
        >
          {item => {
            return (
              <ComboboxItem
                data-test={item.id}
                key={item.id}
                textValue={item.name}
              >
                <Text kind="caption-2">{item.name}</Text>
              </ComboboxItem>
            );
          }}
        </Combobox>
      </Flex>
      <DialogTrigger isOpen={isChangingLocation}>
        {/* A dialog must have a trigger, but I don't want it to show up ... so here we are [DEM 2025/01/24] */}
        <RACButton style={{ display: 'none' }}></RACButton>
        <Dialog data-test="select-country-dialog">
          <Stack gap="$16">
            <DialogTitle>Select Country</DialogTitle>
            <Stack gap="$8">
              {filterData.countries.map(country => (
                <Button
                  color="red"
                  data-test={`country-${country.id}`}
                  key={country.id}
                  kind="secondary"
                  onPress={() => {
                    updateFilterState({
                      type: 'updateSelectedCountryCode',
                      payload: {
                        countryCode: country.abbreviation,
                      },
                    });
                    setIsChangingLocation(false);
                  }}
                  size="small"
                >
                  {country.name}
                </Button>
              ))}
            </Stack>
            <Button
              color="red"
              kind="primary"
              onPress={() => setIsChangingLocation(false)}
              size="small"
            >
              Close
            </Button>
          </Stack>
        </Dialog>
      </DialogTrigger>
    </>
  );
}
