import {
  type RACKey,
  SelectOption,
} from '@iheartradio/web.accomplice/select-field';
import { Playback } from '@iheartradio/web.playback';
import { isBlank, slugify } from '@iheartradio/web.utilities';
import { useLocation, useNavigation, useSearchParams } from '@remix-run/react';
import { useCallback, useMemo, useState } from 'react';
import { isString } from 'remeda';

import { useItemSelected } from '~app/analytics/use-item-selected';
import type { PlaylistsByGenre } from '~app/api/types';
import {
  CardCarousel,
  CardCarouselSlide,
} from '~app/components/card-carousel/card-carousel';
import { CarouselSelect } from '~app/components/carousel-select/carousel-select';
import {
  ContentCard,
  ContentCardImage,
} from '~app/components/content-card/content-card';
import { useIsMobile } from '~app/contexts/is-mobile';
import { useUser } from '~app/contexts/user';
import { useIsMobileBreakpoint } from '~app/hooks/use-is-mobile-breakpoint';
import { Play } from '~app/playback/controls/play';
import type { HomeServerLoaderData } from '~app/routes/_app/_index/.server/loader';
import type { ReducedPlaylistGenres } from '~app/services/types';
import {
  AnalyticsContext,
  delayInPopOverCloseForMobile,
} from '~app/utilities/constants';
import { buildPlaylistUrl } from '~app/utilities/urls';
import { isPremiumUser } from '~app/utilities/user';

export const sectionTitle = 'Playlists By Genre';

interface PlaylistGenreSelectProps {
  selectedGenre: Awaited<
    HomeServerLoaderData['playlistsByGenre']
  >['playlistGenres'][string];
  playlistGenres: Awaited<
    HomeServerLoaderData['playlistsByGenre']
  >['playlistGenres'];
  selectedGenreKey: string;
}

export function PlaylistGenreSelect(props: PlaylistGenreSelectProps) {
  const { playlistGenres, selectedGenreKey } = props;
  const [_searchParams, setSearchParams] = useSearchParams();
  const [isOpen, setIsOpen] = useState(false);
  const isMobileBreakpoint = useIsMobileBreakpoint();

  const items = useMemo(
    () =>
      Object.entries(playlistGenres).map(([genreId, { title: genre }]) => {
        return { key: genreId, label: genre, value: genre };
      }),
    [playlistGenres],
  );

  return (
    <CarouselSelect
      aria-label="Playlist Genre"
      defaultSelectedKey={selectedGenreKey}
      isOpen={isOpen}
      items={items}
      key={selectedGenreKey}
      name="genre"
      onBlur={() => setIsOpen(false)}
      // We want to add some delay in mobile to close select field so that it can't trigger the click event to below element rather than selecting different option
      onOpenChange={isOpen => {
        let delay = 0;
        if (isMobileBreakpoint && isOpen === false) {
          delay = delayInPopOverCloseForMobile;
        }
        setTimeout(() => {
          setIsOpen(isOpen);
        }, delay);
      }}
      onSelectionChange={useCallback(
        (key: RACKey) => {
          if (isString(key) && key !== selectedGenreKey) {
            setSearchParams(
              params => {
                params.set('genre', key.toString());
                return params;
              },
              { preventScrollReset: true },
            );
          }
        },
        [selectedGenreKey, setSearchParams],
      )}
      sectionTitle={sectionTitle}
      selectedKey={selectedGenreKey}
    >
      {item => {
        return (
          <SelectOption key={item.key} textValue={item.label}>
            {item.label}
          </SelectOption>
        );
      }}
    </CarouselSelect>
  );
}

export function Slide({
  index,
  playlist,
  pageName,
  sectionPosition,
  section,
}: {
  index: number;
  pageName: string;
  playlist: Awaited<
    HomeServerLoaderData['playlistsByGenre']
  >['playlists'][number];
  sectionPosition: number;
  section: string;
}) {
  const [userId, id] = String(playlist.catalog?.id).split('::');
  const isPremium = isPremiumUser(useUser());
  const isMobile = useIsMobile();
  const { onItemSelected } = useItemSelected();

  const type =
    isPremium ?
      Playback.StationType.Playlist
    : Playback.StationType.PlaylistRadio;

  const play = Play.usePlaylistPlay({
    context: 0,
    id: `${Number(userId)}::${id}`,
    type,
  });

  return (
    <CardCarouselSlide
      href={buildPlaylistUrl({
        name: playlist.title ?? '',
        userId,
        id,
      })}
      onAction={() => {
        onItemSelected({
          pageName,
          section: slugify(section),
          context: AnalyticsContext.Carousel,
          itemPosition: index,
          sectionPosition,
          assets: {
            asset: {
              id: `${type}|${playlist.catalog?.id}`,
              name: playlist.title ?? '',
            },
          },
        });
      }}
    >
      <ContentCard
        description={playlist.subtitle ?? ''}
        image={
          <ContentCardImage
            alt={playlist.title ?? ''}
            decoding="auto"
            index={index}
            src={playlist?.img_uri || undefined}
            width={isMobile ? 75 : 150}
          />
        }
        imageButton={
          <Play.Playlist
            context={0}
            id={`${Number(userId)}::${id}`}
            shuffle={false}
            size={48}
            type={type}
          />
        }
        isActive={play.playing}
        title={playlist.title ?? ''}
      />
    </CardCarouselSlide>
  );
}

export function PlaylistsByGenreCarousel({
  playlistsByGenre,
  playlistGenres,
  pageName,
  sectionPosition,
  selectedPlaylistGenre,
}: {
  playlistsByGenre: PlaylistsByGenre;
  playlistGenres: ReducedPlaylistGenres;
  pageName: string;
  sectionPosition: number;
  selectedPlaylistGenre: string;
}) {
  const location = useLocation();
  const navigation = useNavigation();

  const selectedGenre = playlistGenres[selectedPlaylistGenre];

  const carouselItems = playlistsByGenre
    .filter(item => item.id || item?.catalog?.id)
    .map((item, index) => ({
      id: (item.id ?? item?.catalog?.id)!,
      item,
      index,
    }));

  return isBlank(playlistsByGenre) ? null : (
      <CardCarousel
        isLoading={
          navigation.state === 'loading' &&
          navigation.location.pathname === location.pathname
        }
        items={carouselItems}
        kind="content"
        title={
          <PlaylistGenreSelect
            playlistGenres={playlistGenres}
            selectedGenre={selectedGenre}
            selectedGenreKey={selectedPlaylistGenre}
          />
        }
      >
        {({ index, item: playlist }) => {
          return (
            <Slide
              index={index}
              pageName={pageName}
              playlist={playlist}
              section={selectedGenre.title}
              sectionPosition={sectionPosition}
            />
          );
        }}
      </CardCarousel>
    );
}
