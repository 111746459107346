import { useQuery } from '@tanstack/react-query';
import { type Dispatch, useEffect, useMemo } from 'react';

import {
  getCountries,
  getFilteredLiveStations,
  getMarketById,
  getMarketGenres,
  getMarkets,
} from './queries';
import type { FilterDataAction } from './radio-dial-filter-data';
import type {
  RadioDialFilterAction,
  RadioDialFilterState,
} from './radio-dial-filter-state';

export function useFilterData(
  filterState: RadioDialFilterState,
  dataDispatch: Dispatch<FilterDataAction>,
  stateDispatch: Dispatch<RadioDialFilterAction>,
) {
  const { data: _countries, status: _countriesStatus } = useQuery({
    queryKey: ['countries'],
    queryFn: getCountries,
  });

  const { data: _markets, status: _marketsStatus } = useQuery({
    queryKey: ['markets', filterState.selectedCountryCode],
    queryFn: () => getMarkets(filterState.selectedCountryCode),
  });

  const { data: _genres, status: _genresStatus } = useQuery({
    queryKey: ['genres'],
    queryFn: () => getMarketGenres(),
  });

  const {
    data: _stations,
    status: _stationsStatus,
    fetchStatus: _stationsFetchStatus,
  } = useQuery({
    queryKey: [
      'stations',
      filterState.activeMarketId,
      filterState.selectedGenreId,
    ],
    queryFn: () =>
      getFilteredLiveStations(
        filterState.activeMarketId,
        filterState.selectedGenreId,
      ),
  });

  const { data: _geoMarket, status: _geoMarketStatus } = useQuery({
    queryKey: ['geoMarket', filterState.geoMarketId],
    queryFn: () => getMarketById(filterState.geoMarketId),
  });

  useEffect(() => {
    if (_countriesStatus === 'success') {
      dataDispatch({
        type: 'updateCountries',
        payload: {
          countries: _countries,
        },
      });
    }
  }, [_countries, _countriesStatus, dataDispatch]);

  useEffect(() => {
    if (_marketsStatus === 'success') {
      dataDispatch({
        type: 'updateMarkets',
        payload: {
          markets: _markets,
        },
      });
    }
  }, [_markets, _marketsStatus, dataDispatch]);

  useEffect(() => {
    if (_genresStatus === 'success') {
      dataDispatch({
        type: 'updateGenres',
        payload: {
          genres: _genres,
        },
      });
      // If the the new genres do not include the currently selected genre, then we must
      // reset the selected genre to 'All Genres'
      if (!_genres.some(genre => genre.id === filterState.selectedGenreId)) {
        stateDispatch({
          type: 'updateSelectedGenreId',
          payload: {
            genreId: 0,
          },
        });
      }
    }
  }, [
    _genres,
    _genresStatus,
    dataDispatch,
    filterState.selectedGenreId,
    stateDispatch,
  ]);

  useEffect(() => {
    if (_stationsStatus === 'success') {
      dataDispatch({
        type: 'updateStations',
        payload: {
          stations: _stations,
        },
      });
    }
  }, [_stations, _stationsStatus, dataDispatch]);

  useEffect(() => {
    if (_geoMarketStatus === 'success') {
      dataDispatch({
        type: 'updateGeoMarket',
        payload: {
          market: _geoMarket,
        },
      });
    }
  }, [_geoMarket, _geoMarketStatus, dataDispatch]);

  return useMemo(
    () => ({
      isFetching: _stationsFetchStatus === 'fetching',
      status:
        (
          [
            _geoMarketStatus,
            _stationsStatus,
            _genresStatus,
            _marketsStatus,
            _countriesStatus,
          ].every(status => status === 'success')
        ) ?
          'success'
        : (
          [
            _geoMarketStatus,
            _stationsStatus,
            _genresStatus,
            _marketsStatus,
            _countriesStatus,
          ].includes('error')
        ) ?
          'error'
        : 'pending',
    }),
    [
      _countriesStatus,
      _genresStatus,
      _geoMarketStatus,
      _marketsStatus,
      _stationsFetchStatus,
      _stationsStatus,
    ],
  );
}
