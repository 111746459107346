import type { Dispatch } from 'react';
import { useMemo, useReducer } from 'react';

import type { LiveStation } from '../filters/state/queries';

type RadioDialState = {
  stations: LiveStation[];
  isFetching: boolean;
  location: string;
  genre: string;
};

type UpdateStationsAction = {
  type: 'updateStations';
  payload: { stations: LiveStation[] };
};
type UpdateFetchStatusAction = {
  type: 'updateFetchStatus';
  payload: { isFetching: boolean };
};
type UpdateLocationAction = {
  type: 'updateLocation';
  payload: { location: string };
};
type UpdateGenreAction = { type: 'updateGenre'; payload: { genre: string } };

type Actions = Pick<
  | UpdateStationsAction
  | UpdateFetchStatusAction
  | UpdateLocationAction
  | UpdateGenreAction,
  'type'
>['type'];

type ActionPayload<T extends Actions> = Extract<
  | UpdateStationsAction
  | UpdateFetchStatusAction
  | UpdateLocationAction
  | UpdateGenreAction,
  { type: T }
>['payload'];
type BatchPayload = Partial<{ [K in Actions]: ActionPayload<K> }>;

export type RadioDialAction =
  | UpdateStationsAction
  | UpdateFetchStatusAction
  | UpdateLocationAction
  | UpdateGenreAction
  | { type: 'batch'; payload: BatchPayload };

function createRadioDialInitialState(): RadioDialState {
  return {
    stations: [],
    isFetching: true,
    location: '',
    genre: 'All Genres',
  };
}

function radioDialReducer(
  state: RadioDialState,
  action: RadioDialAction,
): RadioDialState {
  const { type, payload } = action;
  switch (type) {
    case 'updateFetchStatus': {
      return {
        ...state,
        isFetching: payload.isFetching,
      };
    }
    case 'updateStations': {
      return {
        ...state,
        stations: payload.stations,
      };
    }
    case 'updateGenre': {
      return {
        ...state,
        genre: payload.genre,
      };
    }
    case 'updateLocation': {
      return {
        ...state,
        location: payload.location,
      };
    }
    case 'batch': {
      const newState = { ...state };

      for (const [key, value] of Object.entries(payload) as [
        Actions,
        ActionPayload<Actions>,
      ][]) {
        switch (key) {
          case 'updateFetchStatus': {
            newState['isFetching'] = (
              value as ActionPayload<typeof key>
            ).isFetching;
            break;
          }
          case 'updateGenre': {
            newState['genre'] = (value as ActionPayload<typeof key>).genre;
            break;
          }
          case 'updateLocation': {
            newState['location'] = (
              value as ActionPayload<typeof key>
            ).location;
            break;
          }
          case 'updateStations': {
            newState['stations'] = (
              value as ActionPayload<typeof key>
            ).stations;
            break;
          }
        }
      }

      return newState;
    }
  }
}

export function useRadioDialData(): [
  RadioDialState,
  Dispatch<RadioDialAction>,
] {
  const [liveRadioDialData, dispatch] = useReducer(
    radioDialReducer,
    undefined,
    createRadioDialInitialState,
  );

  return useMemo(
    () => [liveRadioDialData, dispatch],
    [liveRadioDialData, dispatch],
  );
}
