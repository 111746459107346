import type {
  CardImageProps,
  CardProps,
} from '@iheartradio/web.accomplice/card';
import {
  Card,
  CardBody,
  CardImage,
  CardPreview,
  CardPreviewOverlayButtonContainer,
  CardSubtitle,
  CardTitle,
} from '@iheartradio/web.accomplice/card';
import type { ReactNode } from 'react';
import { isNullish } from 'remeda';
import type { SetOptional } from 'type-fest';

import { useImageLoadingProps } from '~app/hooks/use-image-loading-props';

export type FeatureCardProps = CardProps & {
  title?: ReactNode;
  description?: ReactNode;
  image?: ReactNode;
  imageButton?: ReactNode;
};

export function FeatureCard(props: FeatureCardProps) {
  const { isActive, image, imageButton, description, title, ...restProps } =
    props;

  return (
    <Card
      data-test="feature-card"
      isActive={isActive}
      {...restProps}
      orientation="horizontal"
    >
      <CardBody>
        {title ?
          <CardTitle lines={2}>{title}</CardTitle>
        : null}
        {description ?
          <CardSubtitle
            lines={{
              xsmall: 2,
              small: 3,
              shmedium: 2,
              medium: 2,
              large: 3,
              xlarge: 3,
            }}
          >
            {description}
          </CardSubtitle>
        : null}
      </CardBody>
      <CardPreview>
        {image}
        <CardPreviewOverlayButtonContainer>
          {imageButton}
        </CardPreviewOverlayButtonContainer>
      </CardPreview>
    </Card>
  );
}

export type FeatureCardImageProps = SetOptional<CardImageProps, 'src'> & {
  index?: number;
};

export function FeatureCardImage({
  src,
  index,
  ...props
}: FeatureCardImageProps) {
  const { loadingProps } = useImageLoadingProps(index);

  if (isNullish(src)) {
    return null;
  }

  return (
    <CardImage
      {...loadingProps}
      {...props}
      aspectRatio="1 / 1"
      placeholder={false}
      src={src}
    />
  );
}
