import { lightDark, vars } from '@iheartradio/web.accomplice';
import { Box } from '@iheartradio/web.accomplice/box';
import {
  type CardProps,
  Card,
  CardBody,
  CardImage,
  CardPreview,
} from '@iheartradio/web.accomplice/card';
import { Flex } from '@iheartradio/web.accomplice/flex';
import { useFocusWithin, useHover } from '@iheartradio/web.accomplice/hooks';
import { Link } from '@iheartradio/web.accomplice/link';
import { type TextProps, Text } from '@iheartradio/web.accomplice/text';
import {
  type MediaServerURL,
  getResponsiveImgAttributes,
} from '@iheartradio/web.assets';
import { useNavigate } from '@remix-run/react';
import {
  type ReactNode,
  useCallback,
  useRef,
  useState,
  useTransition,
} from 'react';

function NumberRank({
  rank,
  focus,
  noAnchorToImage,
}: {
  rank: number;
  focus: boolean;
  noAnchorToImage?: boolean;
}) {
  const numberStyles: TextProps['css'] = {
    fontSize: { mobile: '5rem', shmedium: '7rem', large: '9rem' },
    lineHeight: 1,
    color:
      focus ?
        lightDark(vars.color.red600, vars.color.red300)
      : lightDark(vars.color.gray100, vars.color.brandBlack),
    fontWeight: '600',
    userSelect: 'none',
    // @ts-expect-error This isn't supported on the type level but functions as expected
    WebkitTextStrokeWidth: focus ? '0' : vars.stroke[1],
    // @ts-expect-error: unable to type WebkitTextStrokeColor
    WebkitTextStrokeColor: lightDark(vars.color.red600, vars.color.red300),
    cursor: 'pointer',
    bottom: noAnchorToImage ? 'unset' : 'anchor(bottom)',
    position: 'absolute',
    transition: 'all ease 300ms',
    textShadow: vars.shadow.elevation1,
  };

  return rank === 10 ?
      <>
        <Text
          aria-hidden="true"
          as="p"
          css={{
            ...numberStyles,
            marginLeft: {
              mobile: '-1.6rem',
              shmedium: '-2rem',
              large: '-2.3rem',
            },
          }}
          kind="h3"
          positionAnchor="--image"
        >
          1
        </Text>
        <Text
          aria-hidden="true"
          as="p"
          css={{
            ...numberStyles,
            marginRight: {
              mobile: '-0.4rem',
              shmedium: '-1rem',
              large: '-1.5rem',
            },
            marginLeft: { medium: '0.2rem', large: '0.5rem' },
          }}
          kind="h3"
          positionAnchor="--image"
        >
          0
        </Text>
      </>
    : <Text
        aria-hidden="true"
        as="p"
        css={{
          ...numberStyles,
          marginRight: { mobile: '-0.4rem', large: '-1.2rem' },
        }}
        kind="h3"
        positionAnchor="--image"
      >
        {rank}
      </Text>;
}

export type TrendingCardProps = Omit<CardProps, 'onPress'> & {
  active?: boolean;
  children?: ReturnType<typeof Text>;
  href: string;
  image: string | URL | MediaServerURL;
  onNavigate?: () => void;
  rank?: number;
  target: '_blank' | '_self';
  title: ReactNode;
};

export function TrendingCard(props: TrendingCardProps) {
  const navigate = useNavigate();

  const [isFocused, setIsFocused] = useState(false);

  const { active, image, title, rank, href, onNavigate, target } = props;

  const { isHovered, hoverProps } = useHover(props);
  const { focusWithinProps } = useFocusWithin({
    onFocusWithinChange: isFocusWithin => setIsFocused(isFocusWithin),
  });

  const linkRef = useRef<HTMLAnchorElement | null>(null);
  const [, startTransition] = useTransition();

  const doNavigation = useCallback(() => {
    onNavigate?.();

    startTransition(() => {
      if (target === '_blank') {
        window.open(href, target);
      } else {
        navigate(href);
      }
    });
  }, [href, navigate, onNavigate, target]);

  const responsiveProps = getResponsiveImgAttributes(image, {
    width: 100,
    ratio: [1, 1],
  });

  return (
    <Flex
      alignItems="center"
      cursor="pointer"
      data-test="trending-card-container"
      height="inherit"
      onClick={() => {
        doNavigation();
      }}
      paddingBottom={vars.space[2]}
      paddingRight={vars.space[2]}
      paddingTop={vars.space[2]}
      position="relative"
      {...hoverProps}
      {...focusWithinProps}
    >
      {rank !== undefined && (
        <Box
          alignItems="center"
          data-test="trending-card-rank"
          display="flex"
          height={{ mobile: '5rem', shmedium: '7rem', large: '8rem' }}
          width={{ mobile: '2.9rem', shmedium: '3.9rem', large: '5.2rem' }}
        >
          <NumberRank
            {...{ rank, focus: isHovered || isFocused }}
            noAnchorToImage
          />
        </Box>
      )}
      <Box height={{ mobile: '6.4rem', large: '9.8rem' }} width="100%">
        <Card
          data-test="trending-card"
          isActive={active}
          isHovered={isHovered}
          onPress={() => {
            doNavigation();
          }}
          orientation="horizontal"
          tabIndex={0}
        >
          <CardPreview>
            <Box asChild borderRadius="unset" boxShadow="unset">
              <CardImage
                alt={title ?? 'trending card image'}
                aspectRatio="1 / 1"
                {...responsiveProps}
              />
            </Box>
          </CardPreview>
          <CardBody>
            <Text
              as="p"
              kind={{ mobile: 'subtitle-4', large: 'subtitle-2' }}
              lines={2}
            >
              <Link
                href={href}
                onPress={() => {
                  onNavigate?.();
                }}
                ref={linkRef}
                target={target}
                underline="none"
              >
                {title}
              </Link>
            </Text>
          </CardBody>
        </Card>
      </Box>
    </Flex>
  );
}

TrendingCard.Link = Link;
