import { type ReactNode, createContext, useContext, useState } from 'react';
import type { HoverProps, Key } from 'react-aria';
import { useHover } from 'react-aria';
import {
  Button as RACButton,
  DropIndicator,
  useDragAndDrop,
} from 'react-aria-components';
import type { ListData } from 'react-stately';

import { Button } from '../../components/button/button.js';
import { MinusFilled } from '../../icons/minus-filled.js';
import { Pencil } from '../../icons/pencil.js';
import { Plus } from '../../icons/plus.js';
import { Preset } from '../../icons/preset.js';
import { lightDark } from '../../media.js';
import { sprinkles } from '../../sprinkles.css.js';
import { vars } from '../../theme-contract.css.js';
import {
  Carousel,
  CarouselContent,
  CarouselHandler,
  CarouselSlide,
} from '../carousel/carousel.js';
import { Dialog, DialogTrigger } from '../dialog/dialog.js';
import { Flex } from '../flex/flex.js';
import { Group } from '../group/group.js';
import { Spacer } from '../spacer/spacer.js';
import { Stack } from '../stack/stack.js';
import { Text } from '../text/text.js';
import {
  backgroundStyles,
  baseBackgroundStyles,
  baseCardStyles,
  cardContainerStyles,
  deleteIconContainerStyles,
  deleteIconStyles,
  dropIndicatorStyles,
  imageStyles,
  overlayStyles,
  paddedContainerRecipe,
  placeholderCardContainerStyles,
  placeholderCardStyles,
  placeholderIconStyles,
  placeholderPaddedContainerStyles,
} from './preset-list.css.js';

export type PresetCardProps<T> = HoverProps & {
  src: string;
  type: string;
  title: string;
  position: T;
  onDeleteAction?: ({
    title,
    position,
  }: {
    title: string;
    position: T;
  }) => void;
};

export type PresetCardSlideProps<T> = PresetCardProps<T> & {
  onAction?: () => void;
};

export function PresetCard<T>({
  src,
  type,
  title,
  position,
  onDeleteAction,
  ...props
}: PresetCardProps<T>) {
  const { isHovered, hoverProps } = useHover(props);
  const isArtist = type === 'ARTIST';

  return (
    <div
      className={cardContainerStyles}
      data-hovered={isHovered || undefined}
      data-test="preset-card"
      {...hoverProps}
      {...props}
    >
      {/* Background div that displays the hover effect */}
      <div className={backgroundStyles} />
      {/* Padded container that provides space for background hover effect to display */}
      <div className={paddedContainerRecipe({ isArtist })}>
        {/* Opacity overlay when hovering over the image only */}
        <div className={overlayStyles} />
        {/* Image container */}
        <div className={baseCardStyles}>
          {/* TODO: There are currently issues with the <Image /> component, when they are resolved the `img` should swapped to `Image`  */}
          <img alt="preset-card-artwork" className={imageStyles} src={src} />
        </div>
      </div>
      {/* Delete icon displayed when card is in edit mode */}
      <DialogTrigger isDismissable>
        <RACButton className={deleteIconContainerStyles}>
          <MinusFilled className={deleteIconStyles} />
        </RACButton>
        {close => (
          <Dialog>
            <Stack align="center" gap={vars.space[16]}>
              <Text
                color={lightDark(vars.color.gray600, vars.color.brandWhite)}
                kind="h5"
              >
                Remove Preset
              </Text>
              <Text
                color={lightDark(vars.color.gray600, vars.color.brandWhite)}
                kind="body-3"
              >
                Are you sure you want to remove this preset?
              </Text>
              <Group gap={vars.space[16]} justify="center">
                <Button
                  color="gray"
                  kind="secondary"
                  onPress={close}
                  size="small"
                >
                  Cancel
                </Button>
                <Button
                  color="red"
                  kind="primary"
                  onPress={() => {
                    onDeleteAction?.({ title, position });
                    close();
                  }}
                  size="small"
                >
                  Remove
                </Button>
              </Group>
            </Stack>
          </Dialog>
        )}
      </DialogTrigger>
      <RACButton slot="drag" style={{ display: 'none' }} />
    </div>
  );
}

export function PresetPlaceholder({ ...props }: HoverProps) {
  const { isHovered, hoverProps } = useHover(props);

  return (
    <div
      className={placeholderCardContainerStyles}
      data-hovered={isHovered || undefined}
      data-test="preset-placeholder"
      role="gridcell"
      {...hoverProps}
      {...props}
    >
      {/* Background div that displays the hover effect */}
      <div className={baseBackgroundStyles} />
      {/* Padded container that provides space for background hover effect to display */}
      <div className={placeholderPaddedContainerStyles}>
        {/* Opacity overlay when hovering over the image only */}
        <div className={overlayStyles} />
        {/* Card container */}
        <div className={placeholderCardStyles}>
          <div
            className={sprinkles({
              color: {
                lightMode: 'gray500',
                darkMode: 'gray200',
              },
            })}
          >
            <Plus className={placeholderIconStyles} />
          </div>
        </div>
      </div>
      <RACButton slot="drag" style={{ display: 'none' }} />
    </div>
  );
}

export function PresetCardSlide<T>({
  onAction,
  onDeleteAction,
  src,
  type,
  title,
  position,
  ...props
}: PresetCardSlideProps<T>) {
  const isEditing = usePresetListState();

  return (
    <CarouselSlide onAction={() => !isEditing && onAction?.()} {...props}>
      <PresetCard
        onDeleteAction={onDeleteAction}
        position={position}
        src={src}
        title={title}
        type={type}
      />
    </CarouselSlide>
  );
}

export function PresetPlaceholderSlide({
  onAction,
  ...props
}: {
  onAction?: () => void;
}) {
  const isEditing = usePresetListState();

  return (
    <CarouselSlide isDisabled={isEditing} onAction={onAction} {...props}>
      <PresetPlaceholder />
    </CarouselSlide>
  );
}

const PresetListStateContext = createContext<boolean | null>(null);

export const usePresetListState = () => {
  const isEditing = useContext(PresetListStateContext);

  if (isEditing === null) {
    throw new Error('usePresetListState must be used within the provider.');
  }

  return isEditing;
};

export function PresetList<
  T extends { id: Key; imageUrl?: string; type?: string },
>({
  children,
  onMove,
  isAnonymous = true,
  presetData,
}: {
  children: (item: T) => ReactNode;
  onMove: (presetData: ListData<T>['items']) => void;
  isAnonymous?: boolean;
  presetData: ListData<T>;
}) {
  const [isEditing, setIsEditing] = useState(false);

  // This state helps keep track of which item you are dragging, by storing the item's index/position in the carousel
  const [draggingItemPosition, setDraggingItemPosition] = useState<
    number | null
  >(null);

  const { dragAndDropHooks } = useDragAndDrop({
    getItems(keys) {
      return [...keys].map(key => ({
        'text/plain': String(key),
      }));
    },

    // This method is called when you drop an item - i.e. when you release your mouse button after dragging one
    onDrop(e) {
      if (draggingItemPosition !== null && e.target.type !== 'root') {
        const droppedItemKey = e.target.key;
        const draggingItemKey = presetData.items[draggingItemPosition].id;

        if (e.target.dropPosition === 'before') {
          presetData.moveBefore(droppedItemKey, [draggingItemKey]);
        } else if (e.target.dropPosition === 'after') {
          presetData.moveAfter(droppedItemKey, [draggingItemKey]);
        }

        setDraggingItemPosition(null);
      }
    },

    // This method is called once you begin dragging an item
    onDragStart(e) {
      const draggedItemKey = e.keys.keys().next().value;
      const draggedItemPosition = presetData.items.findIndex(
        item => item.id === draggedItemKey,
      );

      // Save the item's index/position to state
      setDraggingItemPosition(draggedItemPosition);
    },

    // This renders the vertical line between cards while you are dragging an item
    renderDropIndicator(target) {
      return <DropIndicator className={dropIndicatorStyles} target={target} />;
    },
  });

  return (
    <PresetListStateContext.Provider value={isEditing}>
      <Carousel
        data-editing={isEditing || undefined}
        data-test="presets-carousel"
        slideGap={vars.space[12]}
        slidesToShow={{
          xsmall: 5,
          small: 5,
          medium: 8,
          large: 8,
          xlarge: 10,
        }}
        watchDrag={!isEditing}
      >
        <CarouselHandler>
          <Flex alignItems="center">
            <div
              className={sprinkles({
                color: {
                  lightMode: 'gray600',
                  darkMode: 'brandWhite',
                },
              })}
            >
              <Preset />
            </div>
            <Spacer left={vars.space[4]} right={vars.space[12]}>
              <Text
                color={lightDark(vars.color.gray600, vars.color.brandWhite)}
                kind="h3"
              >
                Presets
              </Text>
            </Spacer>
            <Button
              isDisabled={isAnonymous}
              kind="tertiary"
              onPress={() => {
                // Save presets after moving their positions
                if (isEditing) {
                  onMove(presetData.items);
                }

                setIsEditing(!isEditing);
              }}
              size="small"
            >
              {isEditing ?
                <Text
                  as="p"
                  color={lightDark(vars.color.blue600, vars.color.blue400)}
                  css={{ cursor: 'pointer' }}
                  kind="button-2"
                >
                  Done
                </Text>
              : <div
                  className={sprinkles({
                    color: {
                      lightMode: 'gray300',
                      darkMode: 'gray400',
                    },
                  })}
                >
                  <Pencil />
                </div>
              }
            </Button>
          </Flex>
        </CarouselHandler>
        <CarouselContent<T>
          dragAndDropHooks={dragAndDropHooks}
          items={presetData.items}
        >
          {children}
        </CarouselContent>
      </Carousel>
    </PresetListStateContext.Provider>
  );
}
