import { vars } from '@iheartradio/web.accomplice';
import type { PresetCardSlideProps } from '@iheartradio/web.accomplice/preset-list';
import {
  PresetCardSlide,
  PresetList,
  PresetPlaceholderSlide,
} from '@iheartradio/web.accomplice/preset-list';
import { addToast } from '@iheartradio/web.accomplice/toast';
import type { PresetsTypes } from '@iheartradio/web.api/amp';
import type { CatalogType } from '@iheartradio/web.assets';
import { MediaServerURL } from '@iheartradio/web.assets';
import type { User } from '@iheartradio/web.config';
import { Playback } from '@iheartradio/web.playback';
import type { NavigateFunction } from '@remix-run/react';
import { useNavigate } from '@remix-run/react';
import { useContext, useEffect } from 'react';
import type { Key, ListData } from 'react-stately';
import { useListData } from 'react-stately';
import { $path } from 'remix-routes';
import type { z } from 'zod';

import { PresetsContext, usePresets } from '~app/contexts/presets/presets';
import {
  type PresetIndex,
  addPreset,
  ContentTypes,
} from '~app/contexts/presets/utils';
import { useUser } from '~app/contexts/user';
import { usePlay } from '~app/playback/controls/play/use-play';
import { playback } from '~app/playback/playback';
import { Routes } from '~app/utilities/constants';

export type Preset = {
  id: Key;
  imageUrl?: string;
  type?: z.infer<typeof PresetsTypes>;
  title?: string;
  index: PresetIndex;
};

const PresetStationTypes = {
  playlist: 'COLLECTION',
  artist: 'ARTIST',
  favorites: 'FAVORITES',
  live: 'LIVE',
  podcast: 'PODCAST',
} as const;

const StationTypes = {
  COLLECTION: 'playlist',
  ARTIST: 'artist',
  FAVORITES: 'favorites',
  LIVE: 'live',
  PODCAST: 'podcast',
} as const;

const onAddPreset = ({
  id,
  index,
  isPlaying,
  navigate,
  presetListData,
  station,
  user,
}: {
  id: Key;
  index: PresetIndex;
  isPlaying: boolean;
  navigate: NavigateFunction;
  presetListData: ListData<Preset>;
  station: Playback.Station;
  user: User;
}) => {
  // Prevent saving preset if user is anonymous
  if (user.isAnonymous) {
    addToast({
      kind: 'info',
      text: 'Log in to preset your favorite content for quick and easy access',
      actions: [
        {
          kind: 'tertiary',
          color: 'gray',
          textColor: vars.color.gray600,
          content: 'Log in',
          size: { xsmall: 'small', medium: 'large' },
          onPress: () => navigate($path(Routes.Login, { action: 'auth' })),
        },
        {
          kind: 'tertiary',
          color: 'gray',
          textColor: vars.color.gray600,
          content: 'Sign up',
          size: { xsmall: 'small', medium: 'large' },
          onPress: () => navigate($path(Routes.SignUp)),
        },
      ],
    });
    return;
  }

  // If the player is currently playing and a station object exists...
  if (isPlaying && station) {
    const { type, meta, id: stationId } = station;
    const { title, image } = meta ?? {};
    const stationType =
      PresetStationTypes[type as keyof typeof PresetStationTypes];

    if (type && stationId && title && image) {
      // Attempt to add preset to memory storage and AMP
      const { success } = addPreset({
        id: String(stationId),
        position: index,
        title,
        type: stationType,
      });

      // If successfully added + saved...
      if (success) {
        // Remove the placeholder tile from the UI so a refresh is not needed.
        presetListData.remove(id);
        // Insert a card populated with the content into the slot that was previously a placeholder tile. This prevents carousel layout shift.
        presetListData.insert(Number.parseInt(index), {
          id: String(stationId),
          index,
          type: stationType,
          title,
          imageUrl: image,
        });
      }
    } else {
      addToast({
        kind: 'error',
        text: `There was an issue adding ${title} to your presets. Please try again`,
      });
    }
  } else {
    addToast({
      kind: 'info',
      text: 'Start playing something to add it to your presets',
    });
  }
};

const PresetCard = ({
  ...props
}: PresetCardSlideProps<PresetIndex> & {
  id: Key;
  type: Preset['type'];
}) => {
  const { id, type, title } = props;
  const stationId = type === 'COLLECTION' ? id : Number.parseInt(id as string);

  const { doPlay, isCurrent } = usePlay({
    id: stationId,
    type: StationTypes[type!] as Playback.StationType,
    context: 0,
  });

  return (
    <PresetCardSlide<PresetIndex>
      {...props}
      onAction={() => {
        if (!isCurrent) {
          doPlay();
        } else {
          addToast({
            kind: 'info',
            text: `${title} is currently playing`,
          });
        }
      }}
    />
  );
};

export function PresetsCarousel() {
  const presetData = useContext(PresetsContext);
  const { movePreset, deletePreset } = usePresets();
  const player = playback.usePlayer();
  const playerState = player.getState();
  const user = useUser();
  const navigate = useNavigate();

  // `useListData` is how the presets data is stored + updated
  const presetListData = useListData({
    initialItems: presetData,
  });

  // `useListData` does not support updating it's initialItems when the data changes,
  // so we watch `presetData` and when it changes, we update those keys within the `listData`
  useEffect(() => {
    for (const [index, item] of presetListData.items.entries()) {
      if (item.id !== presetData[index].id) {
        presetListData.update(item.id, presetData[index]);
      }
    }
  }, [presetData]);

  return (
    <PresetList<Preset>
      isAnonymous={user.isAnonymous}
      onMove={presetsData => movePreset(presetsData)}
      presetData={presetListData}
    >
      {item => {
        const { id, type, imageUrl, index, title } = item;
        const imageSrc =
          type ?
            type !== 'COLLECTION' ?
              MediaServerURL.fromCatalog({
                id,
                type: type.toLowerCase() as CatalogType,
              })
                .ratio(1, 1)
                .resize(150)
                .toString()
            : MediaServerURL.fromURL(imageUrl)
                .ratio(1, 1)
                .resize(150)
                .toString()
          : null;

        return id && imageSrc && type ?
            <PresetCard
              id={id}
              onDeleteAction={({ title, position }) => {
                // Attempt to delete the item from memory storage and AMP
                const { success } = deletePreset({ title, position });

                // If successfully deleted + saved...
                if (success) {
                  // Remove the content card from the UI so a refresh is not needed.
                  presetListData.remove(id);
                  // Insert an empty placeholder tile into the slot that was previously a content card. This prevents carousel layout shift.
                  presetListData.insert(Number.parseInt(index), {
                    id: index,
                    index,
                  });
                }
              }}
              position={index}
              src={imageSrc}
              title={title ?? ContentTypes[type]}
              type={type}
            />
          : <PresetPlaceholderSlide
              onAction={() => {
                const station = playerState.get('station');
                const isPlaying =
                  playerState.get('status') === Playback.Status.Playing;

                onAddPreset({
                  id,
                  index,
                  isPlaying,
                  navigate,
                  presetListData,
                  station,
                  user,
                });
              }}
            />;
      }}
    </PresetList>
  );
}
