import type {
  ampContract,
  ClientInferResponses,
} from '@iheartradio/web.api/amp';
import { isNonNullish, prop } from 'remeda';

import { amp } from '~app/api/amp-client';

export type Country = Exclude<
  ClientInferResponses<
    typeof ampContract.v2.content.getCountries
  >['body']['hits'],
  undefined
>[number];
export type Market = Exclude<
  ClientInferResponses<
    typeof ampContract.v2.content.getMarkets
  >['body']['hits'],
  undefined
>[number] & { displayName: string };
export type MarketGenre = Exclude<
  ClientInferResponses<
    typeof ampContract.v3.catalog.getGenres
  >['body']['genres'],
  undefined
>[number];
export type LiveStation = Exclude<
  ClientInferResponses<
    typeof ampContract.v2.content.getLiveStations
  >['body']['hits'],
  undefined
>[number];

export async function getCountries(): Promise<Country[]> {
  return amp.api.v2.content
    .getCountries()
    .then(prop('body'))
    .then(prop('hits'))
    .then(countries => countries ?? [])
    .catch(() => []);
}

export async function getMarkets(countryCode: string): Promise<Market[]> {
  return amp.api.v2.content
    .getMarkets({
      query: {
        countryCode,
        limit: 0,
        sortBy: 'city',
      },
    })
    .then(prop('body'))
    .then(prop('hits'))
    .then(markets =>
      isNonNullish(markets) && markets.length > 0 ? markets : [],
    )
    .then(markets => {
      // Create a `displayName` that formats it how we want to display it, e.g. "New York, NY"
      const mappedMarkets = markets.map(market => ({
        ...market,
        displayName: `${market.city}, ${market.stateAbbreviation}`,
      }));
      // Add "All Locations" to the front of the array
      mappedMarkets.unshift({
        marketId: 0,
        displayName: 'All Locations',
      } as Market);
      return mappedMarkets;
    })
    .catch(() => []);
}

export async function queryMarketByZipCode(
  zipCode?: string,
): Promise<Omit<Market, 'displayName'> | null> {
  return zipCode ?
      amp.api.v2.content
        .getMarkets({
          query: {
            zipCode,
            limit: 0,
          },
        })
        .then(prop('body'))
        .then(prop('hits'))
        .then(markets => markets?.at(0) ?? null)
        .catch(() => null)
    : null;
}

export async function getMarketById(id: number): Promise<Market> {
  return amp.api.v2.content
    .getMarketById({ params: { id } })
    .then(prop('body'))
    .then(market => market as Market)
    .catch(() => ({}) as Market);
}

export async function getMarketGenres(): Promise<MarketGenre[]> {
  return amp.api.v3.catalog
    .getGenres({
      query: {
        genreType: 'liveStation',
      },
    })
    .then(prop('body'))
    .then(prop('genres'))
    .then(genres => [
      {
        id: 0,
        genreName: 'All Genres',
        count: Number.POSITIVE_INFINITY,
      } as MarketGenre,
      ...genres,
    ])
    .catch(() => []);
}

// If the station name does not include the frequency, we must prepend it to the name
// This is a requirement from product
function includeFrequencyInStationName(station: LiveStation): LiveStation {
  if (station.freq && !station.name?.includes(station.freq)) {
    station.name = `${station.freq} ${station.name}`;
  }
  return station;
}

export async function getFilteredLiveStations(
  marketId: number,
  genreId: number,
): Promise<LiveStation[]> {
  return amp.api.v2.content
    .getLiveStations({
      query: {
        ...(marketId > 0 ? { marketId } : {}),
        genreId,
        limit: 25, // max number of stations to scan is 25, so setting that limit here
        ...(marketId === 0 ? { sort: 'cume' } : {}),
      },
    })
    .then(prop('body'))
    .then(prop('hits'))
    .then(stations =>
      isNonNullish(stations) && stations.length > 0 ? stations : [],
    )
    .then(stations => stations.map(includeFrequencyInStationName))
    .catch(() => []);
}
