import { Flex } from '@iheartradio/web.accomplice/flex';
import {
  SelectField,
  SelectOption,
} from '@iheartradio/web.accomplice/select-field';
import { Text } from '@iheartradio/web.accomplice/text';
import { Playback } from '@iheartradio/web.playback';
import { isString, slugify } from '@iheartradio/web.utilities';
import { useNavigation, useSearchParams } from '@remix-run/react';
import { type Key, useCallback, useState } from 'react';
import { isNonNullish } from 'remeda';

import { trackClick } from '~app/analytics/track-click';
import { useItemSelected } from '~app/analytics/use-item-selected';
import {
  CardCarousel,
  CardCarouselSlide,
} from '~app/components/card-carousel/card-carousel';
import { ContentCardImage } from '~app/components/content-card/content-card';
import { RankedContentCard } from '~app/components/content-card/ranked-content-card';
import { useIsMobile } from '~app/contexts/is-mobile';
import {
  SelectInteractionProvider,
  useSelectInteraction,
} from '~app/contexts/select-interaction';
import { useUser } from '~app/contexts/user';
import { Play } from '~app/playback/controls/play';
import { AnalyticsContext } from '~app/utilities/constants';
import { buildPlaylistUrl } from '~app/utilities/urls';
import { isPremiumUser } from '~app/utilities/user';

import type { HomeLoaderData } from '../_home';

export type TopPlaylists = HomeLoaderData['topPlaylists'];

export type PlaylistMoods = HomeLoaderData['playlistMoods'];

export type PlaylistGenres = Exclude<
  HomeLoaderData['playlistGenres'],
  undefined
>;

export type TopPlaylist = Exclude<TopPlaylists, undefined>[number];

type PlaylistGenreSelectProps = {
  genres: PlaylistGenres;
  currentPlaylistGenre: string;
  stateKey?: string;
  pageName: string;
};

type PlaylistMoodSelectProps = {
  moods: PlaylistMoods;
  currentPlaylistMood: string;
  stateKey?: string;
  pageName: string;
};

const sectionTitle = 'Top Playlists';

const BY_PLAYLIST_GENRE_STATE_KEY = 'byPlaylistGenre';

const BY_PLAYLIST_MOOD_STATE_KEY = 'byPlaylistMood';

const ALL_GENRES = 'All Genres';

const ALL_MOODS = 'All Moods';

const selectFieldcss = {
  width: 'auto',
  maxWidth: {
    xsmall: '10.6rem',
    small: '12rem',
    medium: '18rem',
  },
};

export function PlaylistGenreSelect(props: PlaylistGenreSelectProps) {
  const { genres, currentPlaylistGenre, stateKey, pageName } = props;
  const [_searchParams, setSearchParams] = useSearchParams();
  const [isOpen, setIsOpen] = useSelectInteraction();

  const onSelectionChange = useCallback(
    (key: Key) => {
      if (isString(key) && key !== currentPlaylistGenre?.toString()) {
        const filterSelection = genres[key]?.title ?? ALL_GENRES;

        trackClick({
          pageName,
          sectionName: slugify(sectionTitle),
          filterType: 'genre',
          filterSelection,
        });

        setSearchParams(
          prev => {
            prev.set('playlistGenre', key.toString());
            return prev;
          },
          {
            replace: true,
            preventScrollReset: true,
            state: { stateKey },
          },
        );
      }
    },
    [currentPlaylistGenre, setSearchParams, stateKey],
  );

  return (
    <SelectField
      aria-label="playlist genres select field"
      css={selectFieldcss}
      data-test="playlist-genres"
      defaultSelectedKey={currentPlaylistGenre}
      isOpen={isOpen}
      items={[
        { key: '0', label: 'All Genres', value: '0' },
        ...Object.entries(genres).map(([genreId, genre]) => {
          return {
            key: genreId.toString(),
            label: genre.title,
            value: genreId,
          };
        }),
      ]}
      key={`GenreSelect-${currentPlaylistGenre?.toString()}`}
      name="playlistGenres"
      onBlur={() => setIsOpen(false)}
      onOpenChange={(isOpen: boolean) => {
        setIsOpen(isOpen);
      }}
      onSelectionChange={onSelectionChange}
    >
      {item => (
        <SelectOption key={item.key} textValue={item.label}>
          {item.label}
        </SelectOption>
      )}
    </SelectField>
  );
}

export function PlaylistMoodSelect(props: PlaylistMoodSelectProps) {
  const { moods, currentPlaylistMood, pageName } = props;
  const [_searchParams, setSearchParams] = useSearchParams();
  const [isOpen, setIsOpen] = useState(false);

  const onSelectionChange = useCallback(
    (key: Key) => {
      if (isString(key) && key !== currentPlaylistMood.toString()) {
        const filterSelection =
          moods && !Array.isArray(moods) ?
            (moods[key]?.title ?? ALL_MOODS)
          : '';

        trackClick({
          pageName,
          sectionName: slugify(sectionTitle),
          filterType: 'moods',
          filterSelection,
        });

        setSearchParams(
          params => {
            params.set('mood', key.toString());
            return params;
          },
          { preventScrollReset: true },
        );
      }
    },
    [currentPlaylistMood, setSearchParams],
  );

  return (
    <SelectField
      aria-label="playlist-moods"
      css={selectFieldcss}
      data-test="playlist-moods"
      defaultSelectedKey={currentPlaylistMood}
      isOpen={isOpen}
      items={[
        { key: '0', label: 'All Moods', value: '0' },
        ...Object.entries(moods ?? []).map(([moodId, mood]) => {
          return {
            key: moodId ?? '',
            label: mood?.title ?? '',
            value: moodId ?? '',
          };
        }),
      ]}
      key={`mood -${currentPlaylistMood?.toString()}`}
      name="playlistMoods"
      onBlur={() => setIsOpen(false)}
      onOpenChange={(isOpen: boolean) => {
        setIsOpen(isOpen);
      }}
      onSelectionChange={onSelectionChange}
    >
      {item => (
        <SelectOption key={item.key} textValue={item.label}>
          {item.label}
        </SelectOption>
      )}
    </SelectField>
  );
}

function Slide({
  pageName,
  playlist,
  index,
  sectionPosition,
}: {
  pageName: string;
  playlist: TopPlaylist;
  index: number;
  sectionPosition: number;
}) {
  const { onItemSelected } = useItemSelected();
  const isMobile = useIsMobile();
  const user = useUser();
  const isPremium = isPremiumUser(user);

  const type =
    isPremium ?
      Playback.StationType.Playlist
    : Playback.StationType.PlaylistRadio;

  const { playing } = Play.usePlaylistPlay({
    context: 0,
    type,
    id: `${Number(playlist?.resource?.publishedUserId)}::${playlist?.resource?.publishedPlaylistId}`,
  });

  const podcastUrl = buildPlaylistUrl({
    name: playlist?.resource?.title ?? '',
    userId: playlist?.resource?.publishedUserId ?? '',
    id: playlist?.resource?.publishedPlaylistId ?? '',
  });

  return (
    <CardCarouselSlide
      href={podcastUrl}
      onAction={() => {
        onItemSelected({
          pageName,
          section: slugify(sectionTitle),
          context: AnalyticsContext.Carousel,
          itemPosition: index,
          sectionPosition,
          assets: {
            asset: {
              id: `${Playback.StationType.Podcast}|${playlist?.id}`,
              name: playlist?.resource?.title ?? '',
              type: Playback.StationType.Playlist,
            },
          },
        });
      }}
    >
      {({ isFocused, isHovered }) => (
        <RankedContentCard
          {...{ isFocused, isHovered }}
          image={
            <ContentCardImage
              alt={playlist?.resource?.title ?? ''}
              decoding="auto"
              index={index}
              src={playlist?.resource?.urlImage || undefined}
              width={isMobile ? 75 : 150}
            />
          }
          imageButton={
            <Play.Playlist
              context={0}
              id={`${Number(playlist?.resource?.publishedUserId)}::${playlist?.resource?.publishedPlaylistId}`}
              shuffle={false}
              size={48}
              type={type}
            />
          }
          isActive={playing}
          linesForTitle={2}
          rank={index + 1}
          title={playlist?.resource?.title ?? ''}
        />
      )}
    </CardCarouselSlide>
  );
}

export const TopPlaylistsCarousel = ({
  pageName,
  playlists,
  playlistGenres,
  playlistMoods,
  currentPlaylistGenre,
  currentMood,
  sectionPosition,
}: {
  pageName: string;
  playlists: TopPlaylists;
  playlistGenres: PlaylistGenres;
  playlistMoods: HomeLoaderData['playlistMoods'];
  currentPlaylistGenre: HomeLoaderData['currentPlaylistGenre'];
  currentMood: HomeLoaderData['selectedMood'];
  sectionPosition: number;
}) => {
  const navigation = useNavigation();
  const loadedGenre = `mood=${currentMood}`;

  const items = (playlists ?? [])
    .filter(isNonNullish)
    .map((item, index) => ({ ...item, index }));

  return (
    <SelectInteractionProvider>
      <CardCarousel
        isLoading={
          navigation.state === 'loading' &&
          navigation.location.search.includes('mood') &&
          !navigation.location.search.includes(loadedGenre)
        }
        items={items}
        key={`${currentPlaylistGenre ?? 'all_genres'}_${currentMood ?? 'all_moods'}`}
        kind="ranker"
        title={
          <Flex
            alignItems="center"
            gap="$8"
            justifyContent={{ mobile: 'space-between', large: 'flex-start' }}
            paddingRight={{ small: '$8', touch: '$0' }}
            width="100%"
          >
            {sectionTitle ?
              <Text as="h3" kind={{ mobile: 'h4', large: 'h3' }}>
                {sectionTitle}
              </Text>
            : null}
            <Flex gap="$4">
              <PlaylistMoodSelect
                currentPlaylistMood={currentMood ?? '0'}
                moods={playlistMoods}
                pageName={pageName}
                stateKey={BY_PLAYLIST_MOOD_STATE_KEY}
              />
              <PlaylistGenreSelect
                currentPlaylistGenre={currentPlaylistGenre ?? '0'}
                genres={playlistGenres}
                pageName={pageName}
                stateKey={BY_PLAYLIST_GENRE_STATE_KEY}
              />
            </Flex>
          </Flex>
        }
      >
        {({ index, ...playlist }) => (
          <Slide
            index={index}
            key={playlist.id}
            pageName={pageName}
            playlist={playlist}
            sectionPosition={sectionPosition}
          />
        )}
      </CardCarousel>
    </SelectInteractionProvider>
  );
};
