import { Box } from '@iheartradio/web.accomplice/box';
import { ExpandableList } from '@iheartradio/web.accomplice/expandable-list';

import { TrendingCard } from '~app/components/trending-card';

import type { HomeLoaderData } from '../_home';

type TrendingSectionProps = {
  data: HomeLoaderData['trendingData'];
};

export function TrendingSection({ data }: TrendingSectionProps) {
  return (
    <Box paddingX={{ mobile: '$16', medium: '$32' }}>
      <ExpandableList max={data.length} min={4} title="Trending on iHeart">
        {data.map((item, i) => {
          const { link, target } = formatLink(item.link);
          return (
            <TrendingCard
              href={link}
              image={item.image}
              key={i}
              rank={i + 1}
              target={target}
              title={item.title}
            />
          );
        })}
      </ExpandableList>
    </Box>
  );
}

function formatLink(href: string): {
  link: string;
  target: '_blank' | '_self';
} {
  const domain = 'www.iheart.com';
  const index = href.indexOf(domain);
  // doing this because spotlight links come back to us as `/spotlight/<ID>` and do not include domain
  const isSpotlight = href.includes('/spotlight');

  if (index > -1 || isSpotlight) {
    const slice = !isSpotlight ? href.slice(index + domain.length) : href;

    if (
      slice.startsWith('/artist') ||
      slice.startsWith('/browse') ||
      slice.startsWith('/live') ||
      slice.startsWith('/playlist') ||
      slice.startsWith('/podcast') ||
      slice.startsWith('/search') ||
      slice.startsWith('/spotlight')
    ) {
      return { link: slice, target: '_self' };
    }

    return { link: href, target: '_blank' };
  }

  return { link: href, target: '_blank' };
}
