import { lightDark } from '@iheartradio/web.accomplice';
import { Button } from '@iheartradio/web.accomplice/button';
import { Search } from '@iheartradio/web.accomplice/icons';
import { $path } from 'remix-routes';

import { MadeForYouCTA } from '~app/components/made-for-you-cta/made-for-you-cta';
import { Routes } from '~app/utilities/constants';

import { LogoImage } from '../made-for-you-cta/image-helpers';

const title = 'Recently Played';

export const EmptyStateAuth = () => (
  <MadeForYouCTA
    buttons={
      <Button
        color="red"
        data-test="empty-state-auth-search"
        href={$path(Routes.Search)}
        kind="primary"
        size="small"
      >
        <Search size={16} />
        Search
      </Button>
    }
    description="Listen to radio stations, artists, podcasts and playlists to see your listening history here."
    images={
      <LogoImage>
        <Search color={lightDark('$red550', '$red300')} size={32} />
      </LogoImage>
    }
    title={title}
  />
);

export const EmptyStateUnauth = ({ onPress }: { onPress: () => void }) => (
  <MadeForYouCTA
    buttons={
      <>
        <Button
          {...{ onPress }}
          color="red"
          data-test="empty-state-unauth-login"
          href={$path(Routes.Login, { action: 'auth' })}
          kind="primary"
          size="small"
        >
          Log in
        </Button>
        <Button
          {...{ onPress }}
          color={{ dark: 'white', light: 'gray' }}
          data-test="empty-state-unauth-signup"
          href={$path(Routes.SignUp)}
          kind="secondary"
          size="small"
        >
          Sign up
        </Button>
      </>
    }
    description="Log in for free to save your listening history and more."
    title={title}
  />
);
