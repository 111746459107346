import { type ReactNode, Children } from 'react';
import { useDisclosureState } from 'react-stately';

import { lightDark } from '../../media.js';
import { vars } from '../../theme-contract.css.js';
import { Button } from '../button/button.js';
import { Text } from '../text/text.js';
import {
  buttonContainerStyles,
  buttonStyles,
  expandableListContainerStyles,
  expandableListStyles,
} from './expandable-list.css.js';

export type ExpandableListProps = {
  children: ReactNode;
  open?: boolean;
  max: number;
  min?: number;
  title?: string;
};

export function ExpandableList({
  children,
  max = Number.MAX_SAFE_INTEGER,
  min = 1,
  open = false,
  title,
}: ExpandableListProps) {
  const disclosure = useDisclosureState({ defaultExpanded: open });

  const items = Children.toArray(children).slice(
    0,
    disclosure.isExpanded ? max : min,
  );

  return (
    <div className={expandableListContainerStyles}>
      <Text
        as="h3"
        css={{
          color: lightDark(vars.color.gray600, vars.color.brandWhite),
        }}
        kind={{ mobile: 'h4', large: 'h3' }}
      >
        {title}
      </Text>
      <div className={expandableListStyles}>{items}</div>
      <div className={buttonContainerStyles}>
        {max > min && (
          <Button
            className={buttonStyles}
            color={{ dark: 'gray', light: 'white' }}
            inline={false}
            onPress={() => disclosure.toggle()}
            textColor={{ dark: vars.color.blue200, light: vars.color.blue600 }}
          >
            {disclosure.isExpanded ? 'Show Less' : 'Show All'}
          </Button>
        )}
      </div>
    </div>
  );
}
