import { type IconProps, Icon } from './icon.js';

export function Scan(props: Omit<IconProps, 'children'>) {
  return (
    <Icon aria-label="Scan" {...props}>
      <svg viewBox="0 0 16 16">
        <g id="scan">
          <path d="M8.55491 2.02848C8.57817 1.68126 8.44275 1.44272 8.10387 1.33334H7.89553C7.55664 1.44272 7.42123 1.68161 7.44449 2.02848C7.44969 2.10644 7.44896 2.18477 7.44824 2.26308C7.44756 2.3365 7.44689 2.40991 7.45109 2.483C7.46741 2.77466 7.70977 2.99897 7.9997 2.99897C8.28963 2.99897 8.53199 2.77466 8.54831 2.483C8.55251 2.40991 8.55183 2.3365 8.55116 2.26308C8.55043 2.18477 8.54971 2.10644 8.55491 2.02848Z" />
          <path d="M1.33301 5.10939C1.41218 4.90175 1.53266 4.73821 1.76426 4.683C2.06287 4.61182 2.37433 4.80973 2.42884 5.11216C2.4403 5.17571 2.44551 5.24133 2.44551 5.30626C2.44586 6.20418 2.44594 7.10209 2.44603 8.00001C2.44612 8.89793 2.4462 9.79584 2.44655 10.6938C2.44655 10.8681 2.41461 11.0302 2.28856 11.1611C2.01773 11.4431 1.55002 11.3636 1.38509 11.0073C1.37329 10.9816 1.3618 10.9556 1.3503 10.9296L1.33301 10.8906V5.10939Z" />
          <path d="M14.6663 10.8906C14.5872 11.0983 14.4667 11.2618 14.2351 11.317C13.9365 11.3882 13.625 11.1903 13.5705 10.8879C13.559 10.8243 13.5538 10.7587 13.5538 10.6938C13.5535 9.79584 13.5534 8.89793 13.5533 8.00001C13.5532 7.10209 13.5531 6.20418 13.5528 5.30626C13.5528 5.13196 13.5847 4.9698 13.7108 4.8389C13.9816 4.55696 14.4493 4.63647 14.6142 4.99272C14.626 5.01823 14.6374 5.04404 14.6488 5.06986L14.6663 5.10939V10.8906Z" />
          <path d="M7.89553 14.6667C7.55664 14.5573 7.42123 14.3184 7.44449 13.9715C7.44969 13.8936 7.44896 13.8153 7.44824 13.7369C7.44756 13.6635 7.44689 13.5901 7.45109 13.517C7.46741 13.2254 7.70977 13.0011 7.9997 13.0011C8.28963 13.0011 8.53199 13.2254 8.54831 13.517C8.55251 13.5901 8.55183 13.6635 8.55116 13.7369C8.55043 13.8153 8.54971 13.8936 8.55491 13.9715C8.57817 14.3184 8.44275 14.5573 8.10387 14.6667H7.89553Z" />
          <path d="M7.44576 7.05674L7.44607 8.67669C7.44592 9.13648 7.44576 9.59637 7.44623 10.0563C7.44631 10.1676 7.44609 10.279 7.44586 10.3905C7.4452 10.7171 7.44453 11.0441 7.45178 11.3705C7.45768 11.6351 7.6681 11.8517 7.92123 11.8809C8.20213 11.9132 8.44761 11.7625 8.52366 11.4976C8.54935 11.408 8.55213 11.3097 8.55213 11.2153C8.55348 9.54836 8.55336 7.88122 8.55324 6.2142L8.55317 4.78577C8.55317 4.72952 8.55282 4.67293 8.54831 4.61668C8.52122 4.27119 8.17852 4.03716 7.84623 4.13577C7.58511 4.2132 7.44623 4.41702 7.44623 4.73265C7.44548 5.50739 7.44562 6.2821 7.44576 7.05674Z" />
          <path d="M6.61306 8.64652C6.61328 9.14781 6.6135 9.64917 6.61252 10.1504C6.61183 10.4951 6.43475 10.7222 6.13718 10.767C5.82259 10.8142 5.53857 10.5945 5.50419 10.2761C5.50032 10.2403 5.50035 10.2036 5.50037 10.1674L5.5003 8.71804C5.50022 7.75747 5.50014 6.79683 5.50037 5.83647C5.50037 5.49411 5.68891 5.26668 5.99933 5.22918C6.27468 5.19584 6.54239 5.38751 6.59377 5.65973C6.60801 5.73577 6.61183 5.81494 6.61218 5.89272C6.61322 6.42008 6.61309 6.94745 6.61296 7.47481L6.61306 8.64652Z" />
          <path d="M10.5001 8.6417C10.4999 8.42779 10.4997 8.21387 10.4997 8.00001L10.4994 7.90904C10.4994 7.70397 10.4996 7.4989 10.4998 7.29383C10.5003 6.79577 10.5008 6.29772 10.4983 5.79966C10.4966 5.42362 10.1921 5.1698 9.83445 5.23647C9.55459 5.28855 9.38688 5.52188 9.38688 5.86459C9.38618 7.28786 9.38618 8.71147 9.38723 10.1351C9.38723 10.1997 9.39243 10.2653 9.40354 10.3292C9.44139 10.5465 9.61813 10.7229 9.83445 10.7632C10.1924 10.8299 10.4969 10.5764 10.4987 10.2004C10.5011 9.68091 10.5006 9.16125 10.5001 8.6417Z" />
          <path d="M4.66574 7.99793C4.66574 8.11066 4.66615 8.22342 4.66656 8.33619C4.66752 8.59648 4.66847 8.85684 4.66435 9.11703C4.65949 9.4014 4.45532 9.62362 4.18102 9.66112C3.92234 9.69654 3.66192 9.5323 3.58588 9.28022C3.56609 9.21459 3.55532 9.14376 3.55498 9.07536L3.55409 8.78926C3.55213 8.16894 3.55016 7.54838 3.55498 6.92814C3.55845 6.51147 3.9081 6.24411 4.27824 6.36112C4.51192 6.43508 4.66296 6.64202 4.66539 6.90453C4.66757 7.1579 4.66705 7.41144 4.66654 7.66492C4.66631 7.77579 4.66574 7.8871 4.66574 7.99793Z" />
          <path d="M11.3329 7.65598C11.3334 7.76859 11.3339 7.88119 11.3339 7.99376V7.99445C11.3339 8.10531 11.3333 8.21619 11.3329 8.32708C11.3321 8.58925 11.3313 8.85149 11.335 9.11355C11.3378 9.33508 11.4423 9.50452 11.6416 9.60557C11.8315 9.70175 12.0208 9.68612 12.1971 9.56946C12.3853 9.44515 12.4461 9.25765 12.4461 9.04133C12.4457 8.76372 12.4457 8.4861 12.4457 8.20849C12.4458 7.79201 12.4458 7.37552 12.4444 6.95904C12.444 6.87744 12.4346 6.79307 12.411 6.71563C12.3357 6.46772 12.0819 6.3073 11.8263 6.33786C11.5524 6.3705 11.3412 6.59237 11.3357 6.87466C11.3306 7.13501 11.3318 7.39553 11.3329 7.65598Z" />
        </g>
      </svg>
    </Icon>
  );
}
